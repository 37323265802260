import { ResponsiveValue } from '@chakra-ui/react'
import { RestrictedFooter } from '@opengovsg/design-system-react'

// TODO: Extend from RestrictedFooterProps instead when they are exported by the package in the future.
interface AppFooterProps {
  variant?: ResponsiveValue<'full' | 'compact'>
  colorMode?: 'light' | 'dark'
}

export const AppFooter = (props: AppFooterProps): JSX.Element => {
  return (
    <RestrictedFooter
      appLink={window.location.origin}
      appName="AttendGov"
      containerProps={{
        px: 0,
        bg: 'transparent',
        columnGap: '20%',
      }}
      footerLinks={[
        {
          href: 'https://hack.gov.sg/beta/HFPGprivacy/',
          label: 'Privacy',
        },
        {
          href: 'https://hack.gov.sg/beta/HFPGTC/',
          label: 'Terms of Use',
        },
        {
          href: 'https://form.gov.sg/privacy',
          label: 'FormSG Privacy',
        },
        {
          href: 'https://form.gov.sg/terms',
          label: 'FormSG Terms of Use',
        },
        {
          href: 'https://guide.postman.gov.sg/legal/privacy',
          label: 'Postman Privacy',
        },
        {
          href: 'https://guide.postman.gov.sg/legal/t-and-c',
          label: 'Postman Terms of Use',
        },
      ]}
      {...props}
    />
  )
}
